<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card header="Playlist" class="mt-4">
          <b-container fluid>
            <b-row>
              <b-col cols="5">
                
              </b-col>

              <b-col cols="5">

              </b-col>

              <b-col cols="2" class="text-center">
                <img v-bind:src="'https://assets.the-source.eu/frontcover/'+release.ProductDetails.ReportingCode+'/140'" width="140" />
                <p class="mt-3">{{ release.ProductDetails.DeliveryStatus.Description }}</p>
              </b-col>
            </b-row>
          </b-container>

          <p>Kies de tags voor afspeellijst.</p>
          <tag-list :items="tags"></tag-list>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import TagList from '@/components/TagList.vue'

  export default {
    name: 'ReleaseDetailEdit',
    components: {
      TagList
    },
    data () {
      return {
        pageTitle: 'Playlist',
        tags: [],
      }
    },
    props: {
      release: Object,
    },
    mounted () {
      this.$root.$data.crumbs = [
        {to: { name: 'release-search' }, text: 'Playlists'},
        {text: this.release.ProductDetails.Attribution + ' - ' + this.release.ProductDetails.Title}
      ]
    }
  }
</script>